import * as Sentry from "@sentry/solidstart";

import {
  ErrorBoundary,
  type ComponentProps,
  type ValidComponent,
} from "solid-js";

// Wrap Solid"s ErrorBoundary to automatically capture exceptions
const SentryErrorBoundary = Sentry.withSentryErrorBoundary(ErrorBoundary);

export function ProjectErrorBoundary(props: ComponentProps<ValidComponent>) {
  return (
    <SentryErrorBoundary
      fallback={(err, reset) => (
        <div class="todo" onClick={reset}>
          Error: {err.toString()}
        </div>
      )}
    >
      {props.children}
    </SentryErrorBoundary>
  );
}
